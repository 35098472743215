import React, { useState } from 'react';
import { TextField, Button, Box, Stack } from '@mui/material';

function DiscordEmbedForm() {
  const [embeds, setEmbeds] = useState([
    {
      title: '',
      description: '',
      thumbnail: null,
      image: null,
      footer: '',
      postDateTime: '',
    },
  ]);

  const handleInputChange = (index, e) => {
    const newEmbeds = [...embeds];
    newEmbeds[index][e.target.name] = e.target.value;
    setEmbeds(newEmbeds);
  };

  const handleFileChange = (index, e, type) => {
    const newEmbeds = [...embeds];
    newEmbeds[index][type] = e.target.files[0];
    setEmbeds(newEmbeds);
  };

  const handleAddEmbed = () => {
    if (embeds.length < 10) {
      setEmbeds([
        ...embeds,
        {
          title: '',
          description: '',
          thumbnail: null,
          image: null,
          footer: '',
          postDateTime: '',
        },
      ]);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      embeds.forEach((embed, index) => {
        if (embed.thumbnail) { // Only append if thumbnail exists
          formData.append(`thumbnail${index}`, embed.thumbnail);
        }
        if (embed.image) { // Only append if image exists
          formData.append(`image${index}`, embed.image);
        }
        formData.append(`title${index}`, embed.title);
        formData.append(`description${index}`, embed.description);
        formData.append(`footer${index}`, embed.footer);
        formData.append(`postDateTime${index}`, embed.postDateTime);
      });
  
      const response = await fetch('/submit-embeds', {
        method: 'POST',
        body: formData,
      });
  
      const result = await response.json();
      if (response.ok) {
        console.log('Embeds saved:', result);
        alert('Embeds saved successfully!');
      } else {
        console.error('Failed to save embeds:', result.error);
        alert('Failed to save embeds.');
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Network error.');
    }
  };
  
 


  

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Stack spacing={2} sx={{ width: '100%', maxWidth: 480 }}>
        {embeds.map((embed, index) => (
          <Box key={index} sx={{ width: '100%' }}>
            <Button
              variant="contained"
              component="label"
              sx={{ width: '100%', mb: 2 }}
            >
              Upload Thumbnail
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(index, e, 'thumbnail')}
              />
            </Button>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={embed.title}
              onChange={(e) => handleInputChange(index, e)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={embed.description}
              onChange={(e) => handleInputChange(index, e)}
              margin="normal"
              multiline
              rows={4}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ width: '100%', mt: 2 }}
            >
              Upload Image
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(index, e, 'image')}
              />
            </Button>
            <TextField
              fullWidth
              label="Footer"
              name="footer"
              value={embed.footer}
              onChange={(e) => handleInputChange(index, e)}
              margin="normal"
            />
             <TextField
              fullWidth
              type="datetime-local"
              label="Schedule Post Date & Time"
              name="postDateTime"
              value={embed.postDateTime}
              onChange={(e) => handleInputChange(index, e)}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ))}
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
          <Button onClick={handleAddEmbed}>Add Embed</Button>

          
          <Button variant="contained" color="primary" onClick={handleSubmit}>Submit All</Button>

        </Stack>
      </Stack>
    </Box>
  );
}

export default DiscordEmbedForm;




