import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './SignIn'
import Dashboard from './Dashboard';
//import DAILY_NEXUS from './DAILY_NEXUS';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          {/* Redirect to SignIn if no other routes matched */}
          {/* Removed the Route for DAILY_NEXUS because it's nested within Dashboard */}
        </Routes>
      </div>
    </Router>
  );
}


export default App;

